import React from 'react';
import { Typography } from 'antd';
import { useStaticQuery, graphql } from 'gatsby';
import Obfuscate from 'react-obfuscate';

import { Metadata } from '../components';
import StandardLayout from '../layouts/standard';

const ContactMattPage = () => {
	const { markdownRemark } = useStaticQuery(
		graphql`
			query {
        		markdownRemark(fileAbsolutePath: {regex: "/content\/contact/"}) {
					frontmatter {
			  			title
					}
					html
					htmlAst
		  		}
      		}
    	`
	);

	return (
		<StandardLayout>
			<Metadata title={ markdownRemark.frontmatter.title } />
			<Typography.Title level={2}>{markdownRemark.frontmatter.title}</Typography.Title>
			<div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
			<Obfuscate
				email="mattdehnelcomposer@gmail.com"
				headers={{
					subject: 'Contact Matt!'
				}}
			/>
		</StandardLayout>

	)
}

export default ContactMattPage;
